<template>
  <div v-if="permission.access">
    <validation-observer ref="loginValidation">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent
        enctype="multipart/form-data"
      >
        <b-card>
          <b-card-title> Banner Section </b-card-title>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <!-- email -->
                  <b-form-group label="Super Title" label-for="super_title">
                    <validation-provider
                      #default="{ errors }"
                      name="super_title"
                      rules="required"
                    >
                      <b-form-input
                        id="super_title"
                        v-model="form.super_title"
                        :state="errors.length > 0 ? false : null"
                        name="super_title"
                        placeholder="Enter Your Super Title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-4">
                  <!-- email -->
                  <b-form-group label="Course Name Bangla" label-for="name_bn">
                    <validation-provider
                      #default="{ errors }"
                      name="name_bn"
                      rules="required"
                    >
                      <b-form-input
                        id="name_bn"
                        v-model="form.name_bn"
                        :state="errors.length > 0 ? false : null"
                        name="name_bn"
                        placeholder="Enter Your Course Name Bangla"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-4">
                  <!-- email -->
                  <b-form-group label="Course Name English" label-for="name_en">
                    <validation-provider
                      #default="{ errors }"
                      name="name_en"
                      rules="required"
                    >
                      <b-form-input
                        id="name_en"
                        v-model="form.name_en"
                        :state="errors.length > 0 ? false : null"
                        name="name_en"
                        placeholder="Enter Your Course Name English"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <!-- email -->
                  <b-form-group
                    label="Short Description"
                    label-for="short_description"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="short_description"
                      rules="required"
                    >
                      <b-form-textarea
                        id="short_description"
                        v-model="form.short_description"
                        :state="errors.length > 0 ? false : null"
                        name="short_description"
                        placeholder="Enter Your Short Description"
                        rows="4"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-5">
                      <b-form-group label="Button Name 1" label-for="btn_1">
                        <validation-provider
                          #default="{ errors }"
                          name="btn_1"
                          rules="required"
                        >
                          <b-form-input
                            id="btn_1"
                            v-model="form.btn_1"
                            :state="errors.length > 0 ? false : null"
                            name="btn_1"
                            placeholder="Enter Your Button Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div class="col-md-7">
                      <b-form-group
                        label="Button Link 1"
                        label-for="btn_link_1"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="btn_link_1"
                          rules="required"
                        >
                          <b-form-input
                            id="btn_link_1"
                            v-model="form.btn_link_1"
                            :state="errors.length > 0 ? false : null"
                            name="btn_link_1"
                            placeholder="Enter Your Button Link"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-5">
                      <b-form-group label="Button Name 2" label-for="btn_1">
                        <validation-provider
                          #default="{ errors }"
                          name="btn_2"
                          rules="required"
                        >
                          <b-form-input
                            id="btn_2"
                            v-model="form.btn_2"
                            :state="errors.length > 0 ? false : null"
                            name="btn_2"
                            placeholder="Enter Your Button Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div class="col-md-7">
                      <b-form-group
                        label="Button Link 2"
                        label-for="btn_link_2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="btn_link_2"
                          rules="required"
                        >
                          <b-form-input
                            id="btn_link_2"
                            v-model="form.btn_link_2"
                            :state="errors.length > 0 ? false : null"
                            name="btn_link_2"
                            placeholder="Enter Your Button Link"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4">
                      <!-- email -->
                      <b-form-group label="Duration" label-for="duration">
                        <validation-provider
                          #default="{ errors }"
                          name="duration"
                          rules="required"
                        >
                          <b-form-input
                            id="duration"
                            v-model="form.duration"
                            :state="errors.length > 0 ? false : null"
                            name="duration"
                            placeholder="Enter Your Duration"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div class="col-md-4">
                      <!-- email -->
                      <b-form-group label="Total Class" label-for="total_class">
                        <validation-provider
                          #default="{ errors }"
                          name="total_class"
                          rules="required"
                        >
                          <b-form-input
                            id="total_class"
                            v-model="form.total_class"
                            :state="errors.length > 0 ? false : null"
                            name="total_class"
                            placeholder="Enter Your Total Class"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div class="col-md-4">
                      <!-- email -->
                      <b-form-group
                        label="Total Project"
                        label-for="total_project"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="total_project"
                          rules="required"
                        >
                          <b-form-input
                            id="total_project"
                            v-model="form.total_project"
                            :state="errors.length > 0 ? false : null"
                            name="total_project"
                            placeholder="Enter Your Total Project"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-6">
                      <!-- email -->
                      <b-form-group
                        label="Total Student"
                        label-for="total_student"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="total_student"
                          rules="required"
                        >
                          <b-form-input
                            id="total_student"
                            v-model="form.total_student"
                            :state="errors.length > 0 ? false : null"
                            name="total_student"
                            placeholder="Enter Your Total Student"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <!-- email -->
                      <b-form-group
                        label="Total Review"
                        label-for="total_review"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="total_review"
                          rules="required"
                        >
                          <b-form-input
                            id="total_review"
                            v-model="form.total_review"
                            :state="errors.length > 0 ? false : null"
                            name="total_review"
                            placeholder="Enter Your Total Review"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-3">
                      <b-form-group
                        label="Offline Course Fee"
                        label-for="offline_fee"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="offline_fee"
                          rules="required"
                        >
                          <b-form-input
                            id="offline_fee"
                            v-model="form.offline_fee"
                            :state="errors.length > 0 ? false : null"
                            name="offline_fee"
                            placeholder="Enter Your Offline Course Fee"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div class="col-md-3">
                      <b-form-group
                        label="Offline Course Fee Discount"
                        label-for="offline_discount_fee"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="offline_discount_fee"
                        >
                          <b-form-input
                            id="offline_discount_fee"
                            v-model="form.offline_discount_fee"
                            :state="errors.length > 0 ? false : null"
                            name="offline_discount_fee"
                            placeholder="Enter Your Offline Course Fee Discount"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div class="col-md-3">
                      <b-form-group
                        label="Online Course Fee"
                        label-for="online_fee"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="online_fee"
                        >
                          <b-form-input
                            id="online_fee"
                            v-model="form.online_fee"
                            :state="errors.length > 0 ? false : null"
                            name="online_fee"
                            placeholder="Enter Your Online Course Fee"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div class="col-md-3">
                      <b-form-group
                        label="Online Course Fee Discount"
                        label-for="online_discount_fee"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="online_discount_fee"
                        >
                          <b-form-input
                            id="online_discount_fee"
                            v-model="form.online_discount_fee"
                            :state="errors.length > 0 ? false : null"
                            name="online_discount_fee"
                            placeholder="Enter Your Online Course Fee Discount"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="border rounded p-2 mb-2">
                <h4 class="mb-1">Video Setting</h4>

                <b-media
                  no-body
                  vertical-align="center"
                  class="flex-column flex-md-row"
                >
                  <b-media-aside>
                    <b-img
                      :src="video_thumbnail"
                      height="174"
                      width="310"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <b-form-group
                      label="Video Thumbnail"
                      label-for="video_thumbnail"
                    >
                      <b-form-file
                        id="video_thumbnail"
                        accept=".jpg, .png, .gif"
                        @change="loadImage($event)"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                      />
                    </b-form-group>
                    <!-- email -->
                    <b-form-group label="Video ID" label-for="video_url">
                      <validation-provider
                        #default="{ errors }"
                        name="video_url"
                      >
                        <b-form-input
                          id="video_url"
                          v-model="form.video_url"
                          :state="errors.length > 0 ? false : null"
                          name="video_url"
                          placeholder="Enter Your Video ID"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-media-body>
                </b-media>
              </div>
            </div>
          </div>
          <b-form-group label="Select Department" label-for="department_id">
            <validation-provider
              #default="{ errors }"
              name="department_id"
              rules="required"
            >
              <v-select
                id="department_id"
                v-model="form.department_id"
                multiple
                placeholder="Select Department"
                :options="departments"
                label="name_en"
                :reduce="(option) => option.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Select Success Story"
            label-for="success_story_id"
          >
            <validation-provider
              #default="{ errors }"
              name="success_story_id"
              rules="required"
            >
              <v-select
                id="success_story_id"
                v-model="form.success_story_id"
                placeholder="Select Success Story"
                multiple
                :options="options.success_stories"
                label="title"
                :reduce="(option) => option.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-card>
        <b-card>
          <b-card-title> Course Over View </b-card-title>
          <b-form-group label="Title" label-for="course_over_view_title">
            <validation-provider
              #default="{ errors }"
              name="course_over_view_title"
              rules="required"
            >
              <b-form-input
                id="course_over_view_title"
                v-model="form.course_over_view_title"
                :state="errors.length > 0 ? false : null"
                name="course_over_view_title"
                placeholder="Enter Your Course Over View Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div class="d-block" style="height: 410px">
            <b-form-group
              label="Course Over View Description"
              label-for="course_over_view_description"
            >
              <validation-provider
                #default="{ errors }"
                name="course_over_view_description"
                rules="required"
              >
                <!-- :options="snowOption" -->
                <quill-editor
                  id="course_over_view_description"
                  style="height: 320px"
                  class="mb-4"
                  v-model="form.course_over_view_description"
                  name="course_over_view_description"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Your Course Over View Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </b-card>
        <b-card>
          <b-card-title> Course Module Section </b-card-title>
          <b-form-group label="Title" label-for="module_title">
            <validation-provider
              #default="{ errors }"
              name="module_title"
              rules="required"
            >
              <b-form-input
                id="module_title"
                v-model="form.module_title"
                :state="errors.length > 0 ? false : null"
                name="module_title"
                placeholder="Enter Your Module Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Module Topic">
            <div
              class="row mb-1"
              v-for="(module_topic, index) in form.module_items"
              :key="index"
              v-if="form.module_items.length"
            >
              <div class="col-md-10 col-9">
                <validation-provider #default="{ errors }" rules="required">
                  <b-form-input
                    v-model="module_topic.item"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Your Module Topic"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="col-md-2 col-3">
                <b-button
                  v-if="index == 0"
                  variant="primary"
                  class="w-100"
                  type="button"
                  @click="addModuleTopic()"
                >
                  Add More
                </b-button>
                <b-button
                  v-if="index != 0"
                  variant="danger"
                  class="w-100"
                  type="button"
                  @click="removeModuleTopic(index)"
                >
                  Delete
                </b-button>
              </div>
            </div>
          </b-form-group>
        </b-card>
        <b-card>
          <b-card-title> Course Software Section </b-card-title>
          <b-form-group label="Title" label-for="course_software_title">
            <validation-provider
              #default="{ errors }"
              name="course_software_title"
              rules="required"
            >
              <b-form-input
                id="course_software_title"
                v-model="form.course_software_title"
                :state="errors.length > 0 ? false : null"
                name="course_software_title"
                placeholder="Enter Your Course Software Section Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Select Software" label-for="softwares">
            <validation-provider #default="{ errors }" name="softwares">
              <v-select
                id="softwares"
                v-model="form.softwares"
                placeholder="Select Software"
                multiple
                :options="options.softwares"
                label="name"
                :reduce="(option) => option.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-card>
        <b-card>
          <b-card-title> Course for Whom Section </b-card-title>
          <b-form-group label="Title" label-for="course_for_whom_title">
            <validation-provider
              #default="{ errors }"
              name="course_for_whom_title"
              rules="required"
            >
              <b-form-input
                id="course_for_whom_title"
                v-model="form.course_for_whom_title"
                :state="errors.length > 0 ? false : null"
                name="course_for_whom_title"
                placeholder="Enter Your Course for Whom Section Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Select Course for Whom"
            label-for="course_for_whom"
          >
            <validation-provider #default="{ errors }" name="course_for_whom">
              <v-select
                id="course_for_whom"
                v-model="form.course_for_whom"
                placeholder="Select Course for Whom"
                multiple
                :options="options.work_for_whom"
                label="name"
                :reduce="(option) => option.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-card>
        <b-card>
          <b-card-title> Course Job Marketplace Section </b-card-title>
          <b-form-group label="Title" label-for="course_job_marketplace_title">
            <validation-provider
              #default="{ errors }"
              name="course_job_marketplace_title"
              rules="required"
            >
              <b-form-input
                id="course_job_marketplace_title"
                v-model="form.course_job_marketplace_title"
                :state="errors.length > 0 ? false : null"
                name="course_job_marketplace_title"
                placeholder="Enter Your Course Job Marketplace Section Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Select Marketplaces" label-for="marketplaces">
            <validation-provider #default="{ errors }" name="marketplaces">
              <v-select
                id="marketplaces"
                v-model="form.marketplaces"
                placeholder="Select Marketplaces"
                multiple
                :options="options.marketplaces"
                label="name"
                :reduce="(option) => option.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-card>
        <b-card>
          <b-card-title> Course Job Position Section </b-card-title>
          <b-form-group label="Title" label-for="course_job_position_title">
            <validation-provider
              #default="{ errors }"
              name="course_job_position_title"
              rules="required"
            >
              <b-form-input
                id="course_job_position_title"
                v-model="form.course_job_position_title"
                :state="errors.length > 0 ? false : null"
                name="course_job_position_title"
                placeholder="Enter Your Course Job Position Section Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Select Course Job Position"
            label-for="job_positions"
          >
            <validation-provider #default="{ errors }" name="job_positions">
              <v-select
                id="job_positions"
                v-model="form.job_positions"
                placeholder="Select Course Job Position"
                multiple
                :options="options.job_positions"
                label="name"
                :reduce="(option) => option.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-card>
        <b-card>
          <b-card-title> Course Facilities Section </b-card-title>
          <b-form-group label="Title" label-for="course_facilities_title">
            <validation-provider
              #default="{ errors }"
              name="course_facilities_title"
              rules="required"
            >
              <b-form-input
                id="course_facilities_title"
                v-model="form.course_facilities_title"
                :state="errors.length > 0 ? false : null"
                name="course_facilities_title"
                placeholder="Enter Your Course Facilities Section Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Select Course Facilities" label-for="facilities">
            <validation-provider
              #default="{ errors }"
              name="facilities"
              rules="required"
            >
              <v-select
                id="facilities"
                v-model="form.facilities"
                placeholder="Select Course Facilities"
                multiple
                :options="options.facilities"
                label="name"
                :reduce="(option) => option.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-card>
        <b-card>
          <b-card-title> Student Work Section </b-card-title>
          <b-form-group label="Title" label-for="student_work_title">
            <validation-provider
              #default="{ errors }"
              name="student_work_title"
              rules="required"
            >
              <b-form-input
                id="student_work_title"
                v-model="form.student_work_title"
                :state="errors.length > 0 ? false : null"
                name="student_work_title"
                placeholder="Enter Your Student Work Section Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Select Student Work"
            label-for="student_work_title"
          >
            <b-form-file
              multiple
              id="student_work"
              accept=".jpg, .png, .gif"
              @change="workImage($event)"
              placeholder="Select Icon Image"
              drop-placeholder="Drop file here..."
            />
          </b-form-group>
          <div>
            <div
              class="d-inline-block image_div"
              v-for="(image, index) in images"
              :key="index"
              v-if="images.length"
            >
              <div class="image_delete" @click="deleteImage(index)">X</div>
              <b-img
                :src="image"
                height="100"
                width="133"
                class="rounded mr-2 mb-1"
              />
            </div>
          </div>
        </b-card>
        <div class="row">
          <div class="col-md-6">
            <b-card>
              <b-card-title> Comments Section </b-card-title>
              <b-form-group label="Title" label-for="comment_title">
                <validation-provider
                  #default="{ errors }"
                  name="comment_title"
                  rules="required"
                >
                  <b-form-input
                    id="comment_title"
                    v-model="form.comment_title"
                    :state="errors.length > 0 ? false : null"
                    name="comment_title"
                    placeholder="Enter Your Comments Section Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Short Description"
                label-for="comment_description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="comment_description"
                  rules="required"
                >
                  <b-form-textarea
                    id="comment_description"
                    v-model="form.comment_description"
                    :state="errors.length > 0 ? false : null"
                    name="comment_description"
                    placeholder="Enter Your Description"
                    rows="2"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-card>
          </div>
          <div class="col-md-6">
            <b-card>
              <b-card-title> Meta Section </b-card-title>
              <b-form-group label="Meta Title" label-for="meta_title">
                <validation-provider
                  #default="{ errors }"
                  name="meta_title"
                  rules="required"
                >
                  <b-form-input
                    id="meta_title"
                    v-model="form.meta_title"
                    :state="errors.length > 0 ? false : null"
                    name="meta_title"
                    placeholder="Enter Your Meta Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Meta Description"
                label-for="meta_description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="meta_description"
                  rules="required"
                >
                  <b-form-textarea
                    id="meta_description"
                    v-model="form.meta_description"
                    :state="errors.length > 0 ? false : null"
                    name="meta_description"
                    placeholder="Enter Your Meta Description"
                    rows="2"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-card>
          </div>
        </div>

        <b-card>
          <b-card-title> Status Section </b-card-title>

          <b-form-checkbox
            name="is_admission"
            class="mr-5"
            v-model="form.is_admission"
            switch
            inline
          >
            Is Admission
          </b-form-checkbox>
          <b-form-checkbox
            class="mr-5"
            name="is_seminar"
            v-model="form.is_seminar"
            switch
            inline
          >
            Is Seminar
          </b-form-checkbox>
          <b-form-checkbox name="status" v-model="form.status" switch inline>
            Status
          </b-form-checkbox>
        </b-card>
        <div class="d-flex justify-content-end mb-5">
          <b-button type="submit" variant="primary" @click="validationForm">
            Save
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BButton,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardTitle,
  BImg,
  BForm,
  BFormTextarea,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
export default {
  components: {
    BCard,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    quillEditor,
    vSelect,
  },
  data() {
    return {
      required,
      email,
      snowOption: {
        minHeight: 200,
      },
      form: {
        super_title: "",
        department: "",
        name_en: "",
        name_bn: "",
        short_description: "",
        btn_1: "",
        btn_link_1: "",
        btn_2: "",
        btn_link_2: "",
        duration: "",
        total_class: "",
        total_project: "",
        total_student: "",
        offline_fee: 0,
        offline_discount_fee: 0,
        online_fee: 0,
        online_discount_fee: 0,
        total_review: "",
        video_url: "",
        course_over_view_title: "",
        course_over_view_description: "",
        module_title: "",
        module_items: [{ item: "" }],
        video_thumbnail: null,
        success_story_id: [],
        course_software_title: "",
        softwares: [],
        course_for_whom_title: "",
        course_for_whom: [],
        course_job_marketplace_title: "",
        marketplaces: [],
        course_job_position_title: "",
        job_positions: [],
        course_facilities_title: "",
        facilities: [],
        student_work_title: "",
        images: [],
        comment_title: "",
        comment_description: "",
        meta_title: "",
        meta_description: "",
        status: true,
        is_admission: true,
        is_seminar: true,
        department_id: [],
      },
      video_thumbnail: this.$store.state.base_url + "images/course/default.png",
      images: [],
      permission: {
        access: false,
      },
    };
  },
  mounted() {
    this.Permission();
    this.$store.dispatch("getOption");
    this.$store.dispatch("getDepartment");
  },
  methods: {
    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "course.create") {
          this.permission.access = true;
        }
      });
    },

    addModuleTopic() {
      this.form.module_items.push({
        item: "",
      });
    },

    removeModuleTopic(index) {
      this.form.module_items.splice(index, 1);
    },
    deleteImage(index) {
      this.form.images.splice(index, 1);
      this.images.splice(index, 1);
    },

    workImage(e) {
      e.target.files.forEach((data) => {
        this.form.images.push(data);
        let reader = new FileReader();
        reader.onload = (e) => {
          this.images.push(e.target.result);
        };
        reader.readAsDataURL(data);
      });
    },

    loadImage(e) {
      this.form.video_thumbnail = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.video_thumbnail = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    add() {
      const fd = new FormData();
      if (this.form.video_thumbnail) {
        fd.append("video_thumbnail", this.form.video_thumbnail);
      }

      var self = this;
      if (this.form.images.length) {
        for (let i = 0; i < this.form.images.length; i++) {
          let file = self.form.images[i];
          fd.append("images[" + i + "]", file);
        }
      }

      for (let i = 0; i < this.form.module_items.length; i++) {
        let module = self.form.module_items[i];
        fd.append("module_items[" + i + "]", module.item);
      }

      let option_data = [];
      this.form.softwares.forEach((data) => {
        option_data.push(data);
      });
      this.form.course_for_whom.forEach((data) => {
        option_data.push(data);
      });
      this.form.marketplaces.forEach((data) => {
        option_data.push(data);
      });
      this.form.job_positions.forEach((data) => {
        option_data.push(data);
      });
      this.form.facilities.forEach((data) => {
        option_data.push(data);
      });

      for (let i = 0; i < option_data.length; i++) {
        let option = option_data[i];
        fd.append("options[" + i + "]", option);
      }

      for (let i = 0; i < this.form.department_id.length; i++) {
        let department = this.form.department_id[i];
        fd.append("department_id[" + i + "]", department);
      }

      for (let i = 0; i < this.form.success_story_id.length; i++) {
        let success_story_id = this.form.success_story_id[i];
        fd.append("success_story_id[" + i + "]", success_story_id);
      }

      fd.append("super_title", this.form.super_title);
      fd.append("department", this.form.department);
      fd.append("name_en", this.form.name_en);
      fd.append("name_bn", this.form.name_bn);
      fd.append("short_description", this.form.short_description);
      fd.append("btn_1", this.form.btn_1);
      fd.append("btn_link_1", this.form.btn_link_1);
      fd.append("btn_2", this.form.btn_2);
      fd.append("btn_link_2", this.form.btn_link_2);
      fd.append("duration", this.form.duration);
      fd.append("total_class", this.form.total_class);
      fd.append("total_project", this.form.total_project);
      fd.append("total_student", this.form.total_student);
      fd.append("total_review", this.form.total_review);
      fd.append("video_url", this.form.video_url);
      fd.append("course_over_view_title", this.form.course_over_view_title);
      fd.append(
        "course_over_view_description",
        this.form.course_over_view_description
      );
      fd.append("module_title", this.form.module_title);
      fd.append("course_software_title", this.form.course_software_title);
      fd.append("softwares", this.form.softwares);
      fd.append("course_for_whom_title", this.form.course_for_whom_title);
      fd.append("course_for_whom", this.form.course_for_whom);
      fd.append(
        "course_job_marketplace_title",
        this.form.course_job_marketplace_title
      );
      fd.append("marketplaces", this.form.marketplaces);
      fd.append(
        "course_job_position_title",
        this.form.course_job_position_title
      );
      fd.append("job_positions", this.form.job_positions);
      fd.append("course_facilities_title", this.form.course_facilities_title);
      fd.append("facilities", this.form.facilities);
      fd.append("student_work_title", this.form.student_work_title);
      fd.append("comment_title", this.form.comment_title);
      fd.append("comment_description", this.form.comment_description);
      fd.append("meta_title", this.form.meta_title);
      fd.append("meta_description", this.form.meta_description);
      fd.append("status", this.form.status);
      fd.append("is_admission", this.form.is_admission);
      fd.append("is_seminar", this.form.is_seminar);
      fd.append("offline_fee", this.form.offline_fee);
      fd.append("offline_discount_fee", this.form.offline_discount_fee);
      fd.append("online_fee", this.form.online_fee);
      fd.append("online_discount_fee", this.form.online_discount_fee);

      axios
        .post("/app/course/store", fd)
        .then((res) => {
          this.s(res.data.message);
          this.$router.push("/course");
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.name_en) {
              this.e(e.response.data.errors.name_en[0]);
            }
            if (e.response.data.errors.video_thumbnail) {
              this.e(e.response.data.errors.video_thumbnail[0]);
            }
          }
        });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.add();
        }
      });
    },
  },
  computed: {
    options() {
      return this.$store.getters.getOption;
    },
    departments() {
      return this.$store.getters.getDepartment;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.image_div {
  position: relative;
}
.image_delete {
  width: 25px;
  height: 25px;
  font-weight: bold;
  line-height: 25px;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 5px;
  right: 30px;
  background: rgba(0, 0, 0, 0.548);
  cursor: pointer;
  border-radius: 50%;
}
</style>
